<template>
<div>
  <div class="card-box-full" style="overflow: visible">
    <div class="card-box-header">
      Audio Fingerprint Tester
    </div>

    <div class="form-group row" style="margin-top: 20px">
      <div class="col-md-12">

        <div style="margin-left: 5px; margin-bottom: 10px">
          Enter the phone number you would like to call
        </div>
        <input class="form-control" style="width: 400px" v-model="phoneNumber" autocomplete="off" type="tel" placeholder="Enter a phone number" :disabled="isSubmitting" @keyup.enter="submit()">

        <div style="margin-left: 5px; margin-bottom: 10px; margin-top: 20px">
          Select the type of audio fingerprint to play on the call
        </div>
        <table>
          <tr>
            <td>
              <CButton @click="audio = 'spam'" :color="audio == 'spam' ? 'primary' : 'secondary'" :disabled="isSubmitting" style="width: 120px">
                Spam
              </CButton>
            </td>
            <td>
            </td>
            <td>
              <CButton @click="audio = 'not spam'" :color="audio == 'not spam' ? 'primary' : 'secondary'" :disabled="isSubmitting" style="width: 120px">
                Not Spam
              </CButton>
            </td>
          </tr>
        </table>

        <div style="margin-left: 5px; margin-bottom: 10px; margin-top: 20px">
          How many seconds do you want to wait before playing the audio?
        </div>
        <table>
          <tr>
            <td>
              <CButton @click="delay = 0" :color="delay == 0 ? 'primary' : 'secondary'" :disabled="isSubmitting" style="">
                0
              </CButton>
            </td>
            <td>
              <CButton @click="delay = 1" :color="delay == 1 ? 'primary' : 'secondary'" :disabled="isSubmitting" style="">
                1
              </CButton>
            </td>
            <td>
              <CButton @click="delay = 2" :color="delay == 2 ? 'primary' : 'secondary'" :disabled="isSubmitting" style="">
                2
              </CButton>
            </td>
            <td>
              <CButton @click="delay = 3" :color="delay == 3 ? 'primary' : 'secondary'" :disabled="isSubmitting" style="">
                3
              </CButton>
            </td>
            <td>
              <CButton @click="delay = 4" :color="delay == 4 ? 'primary' : 'secondary'" :disabled="isSubmitting" style="">
                4
              </CButton>
            </td>
            <td>
              <CButton @click="delay = 5" :color="delay == 5 ? 'primary' : 'secondary'" :disabled="isSubmitting" style="">
                5
              </CButton>
            </td>
            <td>
              <CButton @click="delay = 6" :color="delay == 6 ? 'primary' : 'secondary'" :disabled="isSubmitting" style="">
                6
              </CButton>
            </td>
            <td>
              <CButton @click="delay = 7" :color="delay == 7 ? 'primary' : 'secondary'" :disabled="isSubmitting" style="">
                7
              </CButton>
            </td>
            <td>
              <CButton @click="delay = 8" :color="delay == 8 ? 'primary' : 'secondary'" :disabled="isSubmitting" style="">
                8
              </CButton>
            </td>
            <td>
              <CButton @click="delay = 9" :color="delay == 9 ? 'primary' : 'secondary'" :disabled="isSubmitting" style="">
                9
              </CButton>
            </td>
            <td>
              <CButton @click="delay = 10" :color="delay == 10 ? 'primary' : 'secondary'" :disabled="isSubmitting" style="">
                10
              </CButton>
            </td>
          </tr>
        </table>

        <hr>

        <CButton @click="submit" color="primary" :disabled="isSubmitting || !audio || !delay || !phoneNumber" style="">
          Place The Phone Call
        </CButton>

        <div v-if="errorMessage">
          <div class="alert alert-danger" style="margin: 15px 0px 0px 0px" role="alert">{{ errorMessage }}</div>
        </div>

      </div>
    </div>

  </div>

  <CModal title="Dial This Number" :show.sync="dialNumber">
    To place your call and play the audio fingerprint, dial the following number...
    <br><br>
    <h1>{{ dialNumber }}</h1>
    <template #footer-wrapper><span></span></template>
  </CModal>

</div>
</template>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s
}

.fade-enter,
.fade-leave-to {
  opacity: 0
}
</style>
<script>
export default {
  name: 'AudioFingerprintTester',
  data() {
    return {
      audio: null,
      delay: null,
      dialNumber: null,
      errorMessage: null,
      isSubmitting: false,
      phoneNumber: null
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    formatPhone: function(phoneNumber) {

      // Make sure we have a valid phone number
      var phone = phoneNumber.replace(/\D/g,'');
      if (phone.length == 11 && phone.substring(0, 1) == "1") {
        // Valid Phone
      } else if (phone.length == 10 && phone.substring(0, 1) != "1") {
        // Prepend a 1 to the number
        phone = "1" + phone;
      } else {
        // Invalid phone
        return phone;
      }

      return "1 (" + phone.substring(1, 4) + ") " + phone.substring(4, 7) + "-" + phone.substring(7, 11);
    },
    //--------------------------------------------------------------------------
    parseMessage(body) {
      try {
        var result = JSON.parse(body);
        return result.message;
      } catch (err) {
        return "";
      }
    },
    //--------------------------------------------------------------------------
    processResponse(response) {
      if (!response || !response.phone) {
        this.errorMessage = "Server did not return a phone number to call";
        return;
      }
      this.dialNumber = this.formatPhone(response.phone);
    },
    //--------------------------------------------------------------------------
    submit() {

      // Make sure we have a valid phone number
      this.phoneNumber = this.phoneNumber.replace(/\D/g, "");
      if (!this.phoneNumber.startsWith("1")) {
        alert("Phone number must begin with 1")
        return;
      }
      if (this.phoneNumber.length != 11) {
        alert("You entered an invalid US phone number to call")
        return;
      }

      // Make sure we have a valid audio
      if (this.audio != "spam" && this.audio != "not spam") {
        alert("Invalid audio fingerprint type: " + this.audio);
        return;
      }

      // Make sure we have a valid delay
      if (this.delay < 0 || this.delay > 10) {
        alert("Invalid delay: " + this.delay);
        return;
      }

      // Make sure we're not submitting
      if (this.isSubmitting) {
        return;
      } else {
        this.isSubmitting = true;
        this.errorMessage = null;
      }

      var body = JSON.stringify({
        destination: this.phoneNumber,
        audio: this.audio,
        delay: parseInt(this.delay)
      });
      var that = this;
      var method = "POST";
      var url = "https://call-filter.platform.robocalls.ai/test-call"
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, body, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isSubmitting = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processResponse(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isSubmitting = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isSubmitting = false;
          that.errorMessage = error;
        }
      })

    }
  },
  mounted() {}
}
</script>
